import React from 'react';
import { BlokComponent } from '../../../types/blok.model';
import SbEditable from 'storyblok-react';
import { RecognitionModule, RecognitionModuleProps } from './index';

const RecognitionModuleBlok: BlokComponent<RecognitionModuleProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <RecognitionModule assets={blok.assets} />
    </SbEditable>
  );
};

export default RecognitionModuleBlok;
