import React from 'react';
import { BlokComponent } from '../../../types/blok.model';
import SbEditable from 'storyblok-react';
import { PartnerLogosModule, PartnerLogosModuleProps } from './index';

const PartnerLogosModuleBlok: BlokComponent<PartnerLogosModuleProps> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <PartnerLogosModule title={blok.title} assets={blok.assets} />
    </SbEditable>
  );
};

export default PartnerLogosModuleBlok;
