import { ImageAsset } from '../../../types/blok.model';
import React from 'react';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import ResponsiveImage from '../../components/ResponsiveImage';
import { ImagesWrapper, Wrapper } from './RecognitionModule.styles';
import { useFallbackAsset } from '../../../util/useFallbackAsset';

export interface RecognitionModuleProps {
  assets: ImageAsset[];
}

export const RecognitionModule: React.FC<RecognitionModuleProps> = ({
  assets,
}) => {
  const { getFallbackAsset } = useFallbackAsset();

  return (
    <Section>
      <Layout>
        <Grid>
          <Wrapper>
            <ImagesWrapper>
              {assets.map((asset) => {
                const fallbackImage = getFallbackAsset(asset);
                if (!fallbackImage) return null;

                return (
                  <ResponsiveImage
                    key={fallbackImage.id}
                    alt={fallbackImage?.alt ?? 'Award image'}
                    mobile={fallbackImage?.filename}
                    tablet={fallbackImage?.filename}
                    desktop={fallbackImage?.filename}
                  />
                );
              })}
            </ImagesWrapper>
          </Wrapper>
        </Grid>
      </Layout>
    </Section>
  );
};
