import React from 'react';
import SbEditable from 'storyblok-react';
import CardList, { CardListProps } from '.';
import CardCaseStudyBlok, {
  CardCaseStudyBlokProps,
} from '../../blocks/CardCaseStudy/CardCaseStudy.blok';
import CardServiceBlok, {
  CardServiceBlokProps,
} from '../../blocks/CardService/CardService.blok';
import CardBlogContentBlok, {
  CardBlogContentBlokProps,
} from '../../blocks/CardBlogContent/CardBlogContent.blok';
import CardReportContentBlok, {
  CardReportContentBlokProps,
} from '../../blocks/CardReportContent/CardReportContent.blok';
import { BlokComponent, StoryblokLink } from '../../../types/blok.model';
import { ensureTrailingSlash } from '../../../util/ensureTranilingSlash';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { VariantTypes } from '.';

export interface CardBlokProps {
  _uid: string;
  component: string;
  contentId: string;
  mobile: { variant?: VariantTypes };
  tablet: { variant?: VariantTypes };
  desktop: { variant?: VariantTypes };
  desktopLarge: { variant?: VariantTypes };
}

interface CardListBlokProps extends Omit<CardListProps, 'ctaLink'> {
  cards: CardBlokProps[];
  ctaLink: StoryblokLink;
  mobileVariant: VariantTypes;
  tabletVariant: VariantTypes;
  desktopVariant: VariantTypes;
  desktopLargeVariant: VariantTypes;
  hideDescription?: boolean;
  hideMetaUpper?: boolean;
  hideMetaLower?: boolean;
}

type CardListCaseStudyBlokProps = CardBlokProps & CardCaseStudyBlokProps;
type CardListServiceBlokProps = CardBlokProps & CardServiceBlokProps;
type CardListBlogContentBlokProps = CardBlokProps & CardBlogContentBlokProps;
type CardListReportContentBlokProps = CardBlokProps &
  CardReportContentBlokProps;

const CardListBlok: BlokComponent<CardListBlokProps> = ({ blok, Dynamic }) => {
  const link = ensureTrailingSlash(
    blok.ctaLink?.cached_url ?? blok.ctaLink?.url
  );

  return (
    <SbEditable content={blok}>
      <CardList
        {...blok}
        ctaLink={link}
        mobile={{ rowGap: spacing.space48, variant: blok.mobileVariant }}
        tablet={{ rowGap: spacing.space64, variant: blok.tabletVariant }}
        desktop={{ rowGap: spacing.space56, variant: blok.desktopVariant }}
        desktopLarge={{
          rowGap: spacing.space56,
          variant: blok.desktopLargeVariant,
        }}
      >
        {blok.cards.map((card) => (
          <React.Fragment key={card._uid}>
            {card.component === 'cardCaseStudy' && (
              <CardCaseStudyBlok
                Dynamic={Dynamic}
                blok={
                  {
                    ...card,
                    hideDescription: blok.hideDescription,
                    hideMetaUpper: blok.hideMetaUpper,
                    hideMetaLower: blok.hideMetaLower,
                  } as CardListCaseStudyBlokProps
                }
              />
            )}

            {card.component === 'cardBlogContent' && (
              <CardBlogContentBlok
                Dynamic={Dynamic}
                blok={
                  {
                    ...card,
                    hideDescription: blok.hideDescription,
                    hideMetaUpper: blok.hideMetaUpper,
                    hideMetaLower: blok.hideMetaLower,
                  } as CardListBlogContentBlokProps
                }
              />
            )}
            {card.component === 'cardReportContent' && (
              <CardReportContentBlok
                Dynamic={Dynamic}
                blok={
                  {
                    ...card,
                    hideDescription: blok.hideDescription,
                    hideMetaUpper: blok.hideMetaUpper,
                    hideMetaLower: blok.hideMetaLower,
                  } as CardListReportContentBlokProps
                }
              />
            )}
            {card.component === 'cardService' && (
              <CardServiceBlok
                Dynamic={Dynamic}
                blok={
                  {
                    ...card,
                  } as CardListServiceBlokProps
                }
              />
            )}
          </React.Fragment>
        ))}
      </CardList>
    </SbEditable>
  );
};

export default CardListBlok;
