import { FC } from 'react';
import { BlokComponentProps } from '../types/blok.model';

import CaseStudyPage from '../new/pages/CaseStudyPage';
import CaseStudyCollectionPage from '../new/pages/CaseStudyCollectionPage';
import NewCaseStudyQuoteBlok from '../new/modules/QuoteModule/QuoteModule.blok';
import NewSummaryModuleBlok from '../new/modules/SummaryModule/SummaryModule.blok';
import NewTextModuleBlok from '../new/modules/TextModule/TextModule.blok';
import NewCaseStudyHeaderModule from '../new/modules/CaseStudyHeaderModule/CaseStudyHeader.blok';
import NewQuoteBlock from '../new/blocks/QuoteBlock/QuoteBlock.blok';
import NewStatsBlock from '../new/blocks/StatsBlock/StatsBlock.blok';
import NewListBlock from '../new/blocks/ListBlock/ListBlock.blok';
import NewTextBlock from '../new/blocks/TextBlock/TextBlock.blok';
import NewImageBlock from '../new/blocks/ImageBlock/ImageBlock.blok';
import NewGalleryModuleSingleBlok from '../new/modules/GalleryModules/GalleryModuleSingle/GalleryModuleSingle.blok';
import NewGalleryModuleDuoBlok from '../new/modules/GalleryModules/GalleryModuleDuo/GalleryModuleDuo.blok';
import NewGalleryModuleTrioBlok from '../new/modules/GalleryModules/GalleryModuleTrio/GalleryModuleTrio.blok';
import CardList from '../new/modules/CardList/CardList.blok';
import CardBlogContentBlok from '../new/blocks/CardBlogContent/CardBlogContent.blok';
import CardReportContentBlok from '../new/blocks/CardReportContent/CardReportContent.blok';
import CardCaseStudyBlok from '../new/blocks/CardCaseStudy/CardCaseStudy.blok';
import CardServiceBlok from '../new/blocks/CardService/CardService.blok';
import FooterBlok from '../new/components/Footer/Footer.blok';
import NavLink from '../new/components/Footer/NavLink.blok';
import CallToActionLink from '../new/components/CallToActionLink/CallToActionLink.blok';
import NewFeaturedBlogRowModule from '../new/modules/FeaturedBlogRow/FeaturedBlogRow.blok';
import NewCTABlock from '../new/blocks/CallToActionBlock/CallToACtion.blok';
import PillarPage from '../new/pages/PillarPage';
import NewPillarHeaderModule from '../new/modules/PillarHeaderModule/PillarHeader.blok';
import NewArticleContentModule from '../new/modules/ArticleContentModule/ArticleContentModule.blok';
import NewArticleImageBlock from '../new/blocks/ArticleImageBlock/ArticleImageBlock.blok';
import NewBlogHeaderModule from '../new/modules/BlogHeaderModule/BlogHeader.blok';
import LandingPage from '../new/pages/LandingPages';
import FootNoteBlock from '../new/blocks/FootNoteBlock/FootNoteBlock.blok';
import NewCollectionHeaderModule from '../new/modules/CollectionHeaderModule/CollectionHeader.blok';
import NewBlogPost from '../new/pages/NewBlogPost';
import BlogCollectionsPage from '../new/pages/BlogCollectionPage';
import VideoScriptBlock from '../new/blocks/VideoScriptBlock/VideoScriptBlock.blok';
import NewBlogHeroImageModule from '../new/modules/BlogHeroImage/BlogHeroImage.blok';
import NewCollectionBlogRow from '../new/modules/CollectionRowBlog/CollectionBlogRow.blok';
import CollectionPage from '../new/pages/CollectionPage';
import ReportPage from '../new/pages/ReportPage/ReportPage';
import ReportsCollectionPage from '../new/pages/ReportsCollectionPage';
import WhitePaperFormModule from '../new/modules/WhitePaperFormModule/WhitePaperFormModule.blok';
import ContactFormModule from '../new/modules/ContactFormModule/ContactFormModule.blok';
import BrandModule from '../new/modules/BrandModule/BrandModule.blok';
import LongformTextModule from '../new/modules/LongformTextModule/LongformTextModule.blok';
import HomePage from '../new/pages/HomePage';
import AiPage from '../new/pages/AiPage';
import ServicesPage from '../new/pages/ServicesPage';
import AboutPage from '../new/pages/AboutPage';
import PublicSectorPage from '../new/pages/PublicSectorPage';
import ContactPage from '../new/pages/ContactPage';
import PageHeaderBlok from '../new/modules/PageHeaderModule/PageHeader.blok';
import CallOutModule from '../new/modules/CallOutModule/CallOut.blok';
import ClientReelModule from '../new/modules/ClientReelModule/ClientReelModule.blok';
import TextFeatureModule from '../new/modules/TextFeatureModule/TextFeatureModule.blok';
import TextFeatureServices from '../new/modules/TextFeatureServices/TextFeatureServices.blok';
import QupteSlideShowModule from '../new/modules/QuoteSlideShowModule/QuoteSlideShowModule.blok';
import QuoteSlideBlock from '../new/blocks/QuoteSlideBlock/QuoteSlideBlock.blok';
import SpacerBlock from '../new/components/Spacer/Spacer.blok';
import VideoGalleryModule from '../new/modules/VideoGalleryModule/VideoGalleryModule.blok';
import VideoSlideShowModule from '../new/modules/VideoSlideShowModule/VideoSlideShowModule.blok';
import StatsModule from '../new/modules/StatsModule/StatsModule.blok';
import PartnerLogosModule from '../new/modules/PartnerLogosModule/PartnerLogosModule.blok';
import RecognitionModuleBlok from '../new/modules/RecognitionModule/RecognitionModule.blok';

const componentMap: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FC<BlokComponentProps<any>>
> = {
  new_QuoteModule: NewCaseStudyQuoteBlok,
  new_SummaryModule: NewSummaryModuleBlok,
  new_CaseStudyHeaderModule: NewCaseStudyHeaderModule,
  new_TextModule: NewTextModuleBlok,
  new_StatsBlock: NewStatsBlock,
  new_ListBlock: NewListBlock,
  new_QuoteBlock: NewQuoteBlock,
  new_TextBlock: NewTextBlock,
  new_ImageBlock: NewImageBlock,
  new_NewGalleryModuleSingle: NewGalleryModuleSingleBlok,
  new_NewGalleryModuleDuo: NewGalleryModuleDuoBlok,
  new_NewGalleryModuleTrio: NewGalleryModuleTrioBlok,
  new_PillarHeaderModule: NewPillarHeaderModule,
  new_ArticleContent: NewArticleContentModule,
  new_ArticleImageBlock: NewArticleImageBlock,
  new_BlogHeaderModule: NewBlogHeaderModule,
  new_FeaturedBlogRow: NewFeaturedBlogRowModule,
  new_CollectionHeaderModule: NewCollectionHeaderModule,
  new_NewBlogHeroImageModule: NewBlogHeroImageModule,
  new_CollectionBlogRowModule: NewCollectionBlogRow,
  VideoScriptBlock: VideoScriptBlock,
  new_FootNoteBlock: FootNoteBlock,
  whitePaperFormModule: WhitePaperFormModule,
  callToActionBlock: NewCTABlock,
  contactFormModule: ContactFormModule,
  brandModule: BrandModule,
  cardListModule: CardList,
  cardReportContent: CardReportContentBlok,
  cardBlogContent: CardBlogContentBlok,
  cardCaseStudy: CardCaseStudyBlok,
  cardService: CardServiceBlok,
  footer: FooterBlok,
  nav_link: NavLink,
  new_landingPage: LandingPage,
  homePage: HomePage,
  aiPage: AiPage,
  servicesPage: ServicesPage,
  publicSectorPage: PublicSectorPage,
  aboutPage: AboutPage,
  contactPage: ContactPage,
  callToActionLink: CallToActionLink,
  pageHeaderModule: PageHeaderBlok,
  callOutModule: CallOutModule,
  clientReelModule: ClientReelModule,
  textFeatureModule: TextFeatureModule,
  textFeatureServicesModule: TextFeatureServices,
  slideQuoteBlock: QuoteSlideBlock,
  slideShowModule: QupteSlideShowModule,
  videoGalleryModule: VideoGalleryModule,
  videoSlideShowModule: VideoSlideShowModule,
  statsModule: StatsModule,
  spacerBlock: SpacerBlock,
  longformTextModule: LongformTextModule,
  recognitionModule: RecognitionModuleBlok,
  pillarPage: PillarPage,
  casestudy: CaseStudyPage,
  new_blogPost: NewBlogPost,
  blogCollectionPage: BlogCollectionsPage,
  caseStudyCollectionPage: CaseStudyCollectionPage,
  tagCollectionPage: CollectionPage,
  categoryCollectionPage: CollectionPage,
  reportPage: ReportPage,
  reportsCollectionPage: ReportsCollectionPage,
  partnerLogosModule: PartnerLogosModule,
};

export default componentMap;
