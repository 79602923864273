import { ImageAsset } from '../../../types/blok.model';
import React from 'react';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import {
  ImageWrapper,
  Wrapper,
  Image,
  Title,
} from './PartnerLogosModule.styles';
import { useFallbackAsset } from '../../../util/useFallbackAsset';

export interface PartnerLogosModuleProps {
  assets: ImageAsset[];
  title?: string;
}

export const PartnerLogosModule: React.FC<PartnerLogosModuleProps> = ({
  assets,
  title,
}) => {
  const { getFallbackAsset } = useFallbackAsset();
  const columnClass = `columns-${Math.min(assets.length ?? 1, 4)}`;

  return (
    <Section>
      <Layout>
        <Grid>
          {title && <Title>{title}</Title>}
          <Wrapper className={columnClass}>
            {assets.map((asset) => {
              const fallbackImage = getFallbackAsset(asset);
              if (!fallbackImage) return null;

              return (
                <ImageWrapper key={fallbackImage.id}>
                  <Image
                    alt={fallbackImage?.alt ?? 'Award image'}
                    mobile={fallbackImage?.filename}
                    tablet={fallbackImage?.filename}
                    desktop={fallbackImage?.filename}
                    objectFit="contain"
                  />
                </ImageWrapper>
              );
            })}
          </Wrapper>
        </Grid>
      </Layout>
    </Section>
  );
};
