import { ImageAsset } from '../types/blok.model';
import defaultTheme from '../styles/themeVariables';
import { useEffect, useState } from 'react';

const breakpoints = defaultTheme.breakpoints;

export const useFallbackAsset = () => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const getFallbackAsset = (asset: ImageAsset) => {
    if (!asset) return null;

    if (width >= parseInt(breakpoints.desktop, 10)) {
      return asset.desktop?.id
        ? asset.desktop
        : asset.tablet?.id
        ? asset.tablet
        : asset.mobile?.id
        ? asset.mobile
        : null;
    }

    if (width >= parseInt(breakpoints.tablet, 10)) {
      return asset.tablet?.id
        ? asset.tablet
        : asset.mobile?.id
        ? asset.mobile
        : asset.desktop?.id
        ? asset.desktop
        : null;
    }

    return asset.mobile?.id
      ? asset.mobile
      : asset.tablet?.id
      ? asset.tablet
      : asset.desktop?.id
      ? asset.desktop
      : null;
  };

  return { getFallbackAsset };
};
