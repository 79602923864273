import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';

export const Wrapper = styled.div`
  width: 100%;
  grid-column: 1 / -1;

  @media ${breakpointUp.desktop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.space24};
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space16};

  div,
  img,
  picture {
    height: 72px;
    width: 272px;
    object-fit: contain;
  }

  @media ${breakpointUp.desktop} {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.space24};

    div,
    img,
    picture {
      width: 300px;
      height: 80px;
    }
  }

  @media ${breakpointUp.lg} {
    div,
    img,
    picture {
      height: 88px;
      width: 333px;
    }
  }
`;
