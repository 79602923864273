import styled from 'styled-components';
import { greyScale, spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import { motion } from 'framer-motion';

export const SlideShow = styled.div``;

export const Track = styled.div`
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  align-content: center;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const Slide = styled(motion.div)`
  scroll-snap-align: center;
  display: grid;
  align-content: center;
`;

export const Nav = styled.div`
  gap: ${spacing.space8};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${breakpointUp.desktop} {
    justify-content: center;
    position: relative;
    gap: ${spacing.space36};
  }

  @media ${breakpointUp.desktopLarge} {
    gap: ${spacing.space36};
  }
`;

export const CustomDot = styled.div`
  display: none;

  @media ${breakpointUp.desktop} {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    pointer-events: none;
  }
`;

export const NavItem = styled.a<{
  active: boolean;
  customNav: boolean;
}>`
  &::before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background-color: ${greyScale.grey600};
  }

  opacity: ${({ active }) => (active ? '1' : '0.25')};
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${({ active }) => (active ? '1' : '0.45')};
  }

  @media ${breakpointUp.desktop} {
    opacity: ${({ active }) => (active ? '1' : '0')};

    &::before {
      display: ${({ customNav }) => (customNav ? 'none' : 'block')};
    }
    &:hover {
      opacity: 0;
    }
  }
`;
