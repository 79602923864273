import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { SpacerProps } from '.';

export const SpacerElement = styled.div<SpacerProps>`
  background: ${({ theme }) => theme.bgPrimary};
  padding-bottom: ${spacing.space160};

  @media ${breakpointUp.tablet} {
    padding-bottom: ${spacing.space168};
  }

  @media ${breakpointUp.desktop} {
    padding-bottom: ${spacing.space250};
  }

  @media ${breakpointUp.desktopLarge} {
    padding-bottom: ${spacing.space250};
  }

  &.medium {
    padding-bottom: ${spacing.space144};

    @media ${breakpointUp.tablet} {
      padding-bottom: ${spacing.space144};
    }

    @media ${breakpointUp.desktop} {
      padding-bottom: ${spacing.space200};
    }

    @media ${breakpointUp.desktopLarge} {
      padding-bottom: ${spacing.space200};
    }
  }

  &.small {
    padding-bottom: ${spacing.space144};

    @media ${breakpointUp.tablet} {
      padding-bottom: ${spacing.space160};
    }

    @media ${breakpointUp.desktop} {
      padding-bottom: ${spacing.space160};
    }

    @media ${breakpointUp.desktopLarge} {
      padding-bottom: ${spacing.space168};
    }
  }

  &.small-bottom {
    padding-bottom: ${spacing.space32};

    @media ${breakpointUp.tablet} {
      padding-bottom: ${spacing.space40};
    }

    @media ${breakpointUp.desktop} {
      padding-bottom: ${spacing.space48};
    }

    @media ${breakpointUp.desktopLarge} {
      padding-bottom: ${spacing.space56};
    }
  }

  &.none {
    padding-bottom: ${spacing.space0};

    @media ${breakpointUp.tablet} {
      padding-bottom: ${spacing.space0};
    }

    @media ${breakpointUp.desktop} {
      padding-bottom: ${spacing.space0};
    }

    @media ${breakpointUp.desktopLarge} {
      padding-bottom: ${spacing.space0};
    }
  }

  &.minimal {
    padding-bottom: ${spacing.space48};

    @media ${breakpointUp.tablet} {
      padding-bottom: ${spacing.space48};
    }

    @media ${breakpointUp.desktop} {
      padding-bottom: ${spacing.space64};
    }

    @media ${breakpointUp.desktopLarge} {
      padding-bottom: ${spacing.space64};
    }
  }

  &.header {
    padding-top: ${spacing.space184};
    padding-bottom: ${spacing.space144};

    @media ${breakpointUp.tablet} {
      padding-top: ${spacing.space264};
      padding-bottom: ${spacing.space144};
    }

    @media ${breakpointUp.desktop} {
      padding-top: ${spacing.space294};
      padding-bottom: ${spacing.space200};
    }

    @media ${breakpointUp.desktopLarge} {
      padding-top: ${spacing.space378};
      padding-bottom: ${spacing.space200};
    }
  }

  &.fill-height {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: end;
    justify-content: center;
    min-height: 18.75rem;
    width: 100%;
    padding-bottom: ${spacing.space32};

    @media ${breakpointUp.tablet} {
      min-height: 25rem;
      padding-bottom: ${spacing.space72};
    }

    @media ${breakpointUp.desktop} {
      min-height: 28.125rem;
      padding-bottom: ${spacing.space72};
    }

    @media ${breakpointUp.desktopLarge} {
      min-height: 35rem;
      padding-bottom: ${spacing.space72};
    }
  }
`;
