import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import { EyebrowText } from '../../components/Eyebrow/Eyebrow.styles';

export const Title = styled(EyebrowText)`
  grid-column: 1 / -1;
`;

export const Image = styled(ResponsiveImage)`
  max-height: 120px;
  max-width: 230px;

  img {
    object-fit: contain !important;
    max-height: 120px;
    max-width: 230px;
  }
`;

export const Wrapper = styled.div`
  grid-column: 1 / -1;
  display: grid;
  gap: ${({ theme }) => theme.space24};
  width: 100%;

  @media ${breakpointUp.tablet} {
    &.columns-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.columns-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.columns-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

export const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  padding: ${({ theme }) => theme.space40} ${({ theme }) => theme.space32};
  align-items: center;
  justify-content: center;
  display: flex;
  height: 248px;
`;
