import styled, { css } from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { GridStyles } from '../../layout/Grid';
import { EyebrowText } from '../../components/Eyebrow/Eyebrow.styles';

export const Body = styled.div`
  color: ${({ theme }) => theme.text400};
  ${typeScale.mobile.body.xs}
  margin-bottom: ${spacing.space24};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.sm}
    margin-bottom: ${spacing.space24};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.md}
    margin-bottom: ${spacing.space40};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.lg}
    margin-bottom: ${spacing.space40};
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textPrimary};
  white-space: break-spaces;

  ${typeScale.mobile.body.lg}
  margin-bottom: ${spacing.space24};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.lg}
    margin-bottom: ${spacing.space24};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xxl}
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxxl}
    margin-bottom: ${spacing.space32};
  }

  .title & {
    ${typeScale.mobile.body.xxl}
    margin-bottom: ${spacing.space24};

    @media ${breakpointUp.tablet} {
      ${typeScale.desktop.body.xxl}
      margin-bottom: ${spacing.space24};
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.xxxl}
      margin-bottom: ${spacing.space24};
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.xxxxl}
      margin-top: ${spacing.space16};
      margin-bottom: ${spacing.space32};
    }
  }
`;

export const Meta = styled.div``;

const List = css`
  ul {
    padding: 0;
    margin-top: 0;
    margin-bottom: ${spacing.space48};
    ${typeScale.mobile.body.sm}
    flex-direction: column;
    display: flex;
    row-gap: ${spacing.space8};

    @media ${breakpointUp.tablet} {
      flex-wrap: wrap;
      flex-direction: row;
      ${typeScale.tablet.body.lg}
      row-gap: ${spacing.space4};

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.md}
      row-gap: ${spacing.space4};
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.lg}
      margin-bottom: ${spacing.space40};
      row-gap: ${spacing.space8};
    }
  }
`;

const ListItem = css`
  li {
    p {
      margin: 0;
      display: inline;
    }

    &::marker {
      font-size: 0;
    }

    @media ${breakpointUp.tablet} {
      &::after {
        content: '/';
        margin: 0 ${spacing.space8};
        color: ${({ theme }) => theme.text500};
      }

      &:first-child::after,
      &:last-child::after {
        content: '';
        margin: 0;
      }

      &:first-child {
        width: 100%;
      }
    }
  }
`;

export const Container = styled.div`
  &.two-column {
    ${GridStyles}

    ${EyebrowText} {
      grid-column: 1 / -1;
    }

    ${Title} {
      grid-column: 1 / -1;
      ${typeScale.mobile.body.lg}
      margin-top: 0;
      margin-bottom: ${spacing.space48};
      white-space: break-spaces;
    }

    ${Body} {
      grid-column: 1 / -1;
      ${typeScale.mobile.body.xs}
      color: ${({ theme }) => theme.textPrimary};

      ${List}
      ${ListItem}

      .textSecondary {
        color: ${({ theme }) => theme.text500};
      }
    }

    ${Meta} {
      grid-column: 1 / -1;
    }

    @media ${breakpointUp.tablet} {
      ${Title} {
        grid-column: 1 / span 7;
        ${typeScale.tablet.body.xxl}
        margin-top: 0;
        margin-bottom: ${spacing.space72};
      }

      ${Body} {
        grid-column: 1 / span 8;
        ${typeScale.tablet.body.lg}
      }

      ${Meta} {
        grid-column: 1 / -1;
      }

      @media ${breakpointUp.desktop} {
        ${Title} {
          grid-column: 1 / span 5;
          ${typeScale.desktop.body.xxxl}
          margin: 0;
        }

        ${Body} {
          grid-column: span 6 / -1;
          ${typeScale.desktop.body.xl}
          max-width: 37rem;
          margin: 0;
        }

        ${Meta} {
          grid-column: span 6 / -1;
        }
      }

      @media ${breakpointUp.desktopLarge} {
        ${Title} {
          grid-column: 1 / span 5;
          ${typeScale.desktop.body.xxxxl}
          margin: 0;
        }

        ${Body} {
          grid-column: span 6 / -1;
          ${typeScale.desktop.body.xxl}
          max-width: 50rem;
          margin: 0;
        }

        ${Meta} {
          grid-column: span 6 / -1;
        }
      }
    }
  }
`;
